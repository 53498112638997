import React from 'react'
import MainLayout from '../layouts/Main'

export default function Loader() {
  return (
    <MainLayout>
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
            flexDirection: 'column',
            fontSize: '2rem',
        }}>
            <div style={{
                textAlign: 'center',
            }}>
                <i className="fa fa-spinner fa-spin"></i>
            </div>
        </div>
    </MainLayout>
  )
}
