import { Box, Text, Flex, Image } from "@chakra-ui/react";
import { minusIcon, plusIcon } from "../assets";
import { FAQS } from "../utils/data";
import { useState } from "react";
import { current } from "@reduxjs/toolkit";
const FaqSection = () => {
  const [currentFaq, setCurrentFaq] = useState(0);
  return (
    <Box as="section" width={["90%", null, "60%"]} mx="auto">
      <Flex direction="column" alignItems="center">
        <Text
          as="h2"
          color="black.100"
          fontSize={["24px", null, "48px"]}
          fontWeight="medium"
          mb={["8px", null, "16px"]}
          w={["100%", null, "50%"]}
          textAlign="center"
        >
          Frequently Asked Questions
        </Text>
        <Text
          as="p"
          color="grey.500"
          fontSize={["12px", null, "20px"]}
          mb={["24px", null, "48px"]}
          w={["100%", null, "70%"]}
          textAlign="center"
        >
          You will find answers to the questions we get asked the most
        </Text>
      </Flex>
      <Flex direction="column" gap={["12px", null, "24px"]}>
        {FAQS.map((faq, index, arr) => (
          <FaqBlock
            currentFaq={currentFaq}
            setCurrentFaq={setCurrentFaq}
            question={faq.question}
            answer={faq.answer}
            key={index}
            index={index}
            lastItem={index === arr.length - 1}
          />
        ))}
      </Flex>
    </Box>
  );
};

export default FaqSection;

const FaqBlock = (props) => {
  const { question, answer, index, currentFaq, setCurrentFaq, lastItem } =
    props;
  const open = currentFaq === index;

  const openFaqHandler = () => {
    currentFaq === index ? setCurrentFaq(null) : setCurrentFaq(index);
  };
  return (
    <Box
      py={["16px", null, "32px"]}
      borderBottom={lastItem ? "none" : "1px solid #EAECF0"}
    >
      <Flex
        onClick={openFaqHandler}
        justifyContent="space-between"
        w="100%"
        bgColor="transparent"
        alignItems="flex-start"
        mb="8px"
        cursor="pointer"
      >
        <Text
          as="p"
          color="black.400"
          fontSize={["16px", null, "20px"]}
          fontWeight="medium"
        >
          {question}
        </Text>
        <Image src={open ? minusIcon : plusIcon} />
      </Flex>
      {open && (
        <Text as="p" color="grey.600" fontSize={["12px", null, "16px"]}>
          {answer}
        </Text>
      )}
    </Box>
  );
};
