import { Box, Image, Text, Flex } from "@chakra-ui/react";
import {
  Live,
  Adebola,
  Akolag,
  Waves,
  Binca,
  QuickGo,
  Royal,
  Manifest,
  Primes,
} from "../assets";
import logo1 from "../assets/logos/1.png";
import logo2 from "../assets/logos/2.png";
import logo3 from "../assets/logos/3.png";
import logo4 from "../assets/logos/4.png";
import logo5 from "../assets/logos/5.png";
import logo6 from "../assets/logos/6.png";
import logo7 from "../assets/logos/7.png";
import logo8 from "../assets/logos/8.png";
import logo9 from "../assets/logos/9.png";
import logo10 from "../assets/logos/10.png";
import logo11 from "../assets/logos/11.png";
import logo13 from "../assets/logos/13.png";
import logo14 from "../assets/logos/14.png";
import logo15 from "../assets/logos/15.png";
import logo16 from "../assets/logos/16.png";
import logo17 from "../assets/logos/17.png";
import logo18 from "../assets/logos/18.png";
import logo19 from "../assets/logos/19.png";
import logo20 from "../assets/logos/20.png";
import logo21 from "../assets/logos/21.png";
import logo22 from "../assets/logos/22.png";
import logo23 from "../assets/logos/23.png";
import logo24 from "../assets/logos/24.png";
import logo25 from "../assets/logos/25.png";
import logo26 from "../assets/logos/26.png";
import logo27 from "../assets/logos/27.png";
import logo28 from "../assets/logos/28.png";
import logo29 from "../assets/logos/29.png";
import logo30 from "../assets/logos/30.png";
import logo31 from "../assets/logos/31.png";
import logo32 from "../assets/logos/32.png";
import logo33 from "../assets/logos/33.png";
import logo34 from "../assets/logos/34.png";
import logo35 from "../assets/logos/35.png";
import logo36 from "../assets/logos/36.png";
import logo37 from "../assets/logos/37.png";
import logo38 from "../assets/logos/38.png";
import logo39 from "../assets/logos/39.png";
import logo40 from "../assets/logos/40.png";
import logo41 from "../assets/logos/41.png";
import logo42 from "../assets/logos/42.png";
import logo43 from "../assets/logos/43.png";
import logo44 from "../assets/logos/44.png";
import logo45 from "../assets/logos/45.png";
import logo46 from "../assets/logos/46.png";
import logo47 from "../assets/logos/47.png";
import logo48 from "../assets/logos/48.png";
import logo49 from "../assets/logos/49.png";
import logo50 from "../assets/logos/50.png";
import logo51 from "../assets/logos/fetch.jpeg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const Partners = () => {
  const PARTNER_LOGOS = [
    logo51,
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
    logo8,
    logo9,
    logo10,
    logo11,
    logo13,
    logo14,
    logo15,
    logo16,
    logo16,
    logo17,
    logo18,
    logo19,
    logo20,
    logo21,
    logo22,
  ];
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    loop: true,
    dots: false,
    autoplay: true,
    navText: ["Prev", "Next"],
    smartSpeed: 1000,
    autoplayTimeout: 2000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  };
  return (
    <Box
      mt={["24px", null, "84px"]}
      mb={["32px", null, "64px"]}
      className="container"
    >
      <Text
        color="grey.300"
        fontWeight="500"
        fontSize={["sm", null, "xl"]}
        textAlign="center"
        mb={["14px", null, "32px"]}
      >
        Join thousands of businesses worldwide who are already members of
        ClubConnect.
      </Text>
      <Flex gap="30px" alignItems="center">
        <OwlCarousel className="owl-theme" {...options}>
          {PARTNER_LOGOS.map((partner, index) => (
            <Image src={partner} key={index} height="100px" width="auto" />
          ))}
        </OwlCarousel>
      </Flex>
    </Box>
  );
};

export default Partners;
