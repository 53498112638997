import { Box, Text, Flex, Image, Grid } from "@chakra-ui/react";
import { BANNERS } from "../utils/data";
import { servicesImg } from "../assets";
const Banners = () => {
  return (
    <Box as="section" className="container">
      <Flex direction="column" gap="8px" alignItems="center">
        <Text as="p" color="#FD8204" fontSize={["14px", null, "16px"]}>
          Why to Join Us
        </Text>
        <Text
          as="h2"
          color="black.200"
          fontSize={["24px", null, "48px"]}
          fontWeight="400"
          textAlign="center"
        >
          Join the growing community.
        </Text>
        <Text
          as="p"
          color="grey.500"
          fontSize={["12px", null, "xl"]}
          textAlign="center"
          w="75%"
        >
          Ensure You Maximize Your Return on Investment by Joining Club Connect
          Community
        </Text>
      </Flex>
      <Image src={servicesImg} alt="Servvices image" mt="32px" width="100%" />
      <Text
        as="h2"
        color="black.200"
        fontSize={["24px", null, "48px"]}
        fontWeight="400"
        textAlign="center"
        mt={["32px", null, "64px"]}
      >
        Benefits of Membership
      </Text>
      <Grid
        as="section"
        templateColumns={["1fr", null, "repeat(6, 1fr)"]}
        columnGap="26px"
        rowGap={["30px", null, "64px"]}
        // mt={["32px", null, "64px"]}
      >
        {BANNERS.map((bannerData, index, arr) => (
          <BannerCard data={bannerData} key={index} index={index} />
        ))}
      </Grid>
    </Box>
  );
};

export default Banners;

const BannerCard = (props) => {
  const { data, index } = props;
  const { title, text, bgColor, icon } = data;
  const lastTwo = index === 3 || index === 4;
  let columnStart;
  if (index === 3) {
    columnStart = 2;
  } else if (index === 4) {
    columnStart = 4;
  } else {
    columnStart = "auto";
  }
  console.log(columnStart);
  return (
    // <Flex
    //   gridColumn={lastCard ? ["auto", null, "span 2"] : ""}
    //   direction={lastCard ? "column-reverse" : "row"}
    //   gap={lastCard ? ["10px", null, "32px"] : ["18px", null, "24px"]}
    //   bgColor={bgColor}
    //   alignItems={lastCard ? "end" : "center"}
    //   p={
    //     lastCard
    //       ? ["25px", null, "60px 80px 30px 30px"]
    //       : ["28px 28px 28px 14px", null, "50px 50px 30px 25px"]
    //   }
    //   borderRadius="10px"
    // >
    //   <Image
    //     src={icon}
    //     alt={title}
    //     h={lastCard ? ["120px", null, "auto"] : ["50px", null, "auto"]}
    //     w={lastCard ? ["120px", null, "auto"] : ["50px", null, "auto"]}
    //   />
    //   <Box as="article">
    //     <Text
    //       color="black.300"
    //       fontWeight="600"
    //       as="h2"
    //       fontSize={lastCard ? ["20px", null, "48px"] : ["16px", null, "30px"]}
    //     >
    //       {title}
    //     </Text>
    //     <Text
    //       as="p"
    //       color="grey.400"
    //       fontSize={lastCard ? ["14px", null, "24px"] : ["12px", null, "16px"]}
    //     >
    //       {text}
    //     </Text>
    //   </Box>
    // </Flex>
    <Flex
      direction="column"
      gap={["16px"]}
      as="article"
      gridColumn={[null, null, `${columnStart}/span 2`]}
    >
      <Image src={icon} alt={title} />
      <Box>
        <Text
          as="h2"
          fontSize={["16px", null, "24px"]}
          fontWeight="600"
          color="#101828"
        >
          {title}
        </Text>
        <Text as="p" fontSize={["12px", null, "16px"]} color="grey.600">
          {text}
        </Text>
      </Box>
    </Flex>
  );
};
