import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "font-awesome/css/font-awesome.min.css";
import "./css/style.css";
import "./css/utilities.css";
import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import RouteRegistrar from "./routes";
import { ChakraBaseProvider } from "@chakra-ui/react";
import theme from "./theme/theme";

const history = createBrowserHistory();
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    {/* <GoogleOAuthProvider clientId="488784573674-j4sb6jsfdro7svsbi1lh699hume4ldad.apps.googleusercontent.com"> */}
    <ChakraBaseProvider theme={theme}>
      <Provider store={store}>
        <RouteRegistrar history={history} />
      </Provider>
    </ChakraBaseProvider>
    {/* </GoogleOAuthProvider> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
