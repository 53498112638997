import { CONSTANTS } from "../constants";

const initialState = {
    data: null,
    error: null
};

export default function authReducer(state = initialState, action) {
    switch (action.type) {
        case CONSTANTS.AUTH.LOGIN_REQUEST: case CONSTANTS.AUTH.REGISTER_REQUEST:
            return {
                ...state,
                data: null,
                error: null
            };
        case CONSTANTS.AUTH.LOGIN_SUCCESS: case CONSTANTS.AUTH.REGISTER_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: null
            };
        case CONSTANTS.AUTH.LOGIN_FAILURE: case CONSTANTS.AUTH.REGISTER_FAILURE:
            return {
                ...state,
                error: action.payload
            };
        case CONSTANTS.AUTH.LOGOUT:
            return {
                ...state,
                data: null
            };
        default:
            return state;
    }
}