import { useState } from "react";
import { connectLogo } from "../assets";
import { Flex, Image, Box, Button, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { IoIosMenu, IoIosClose } from "react-icons/io";
import { getCurrentUser } from "../utils/utils";
import { authServices } from "../services/auth";
const NavBar = () => {
  const [navOpen, setNavOpen] = useState(false);
  const toggleNavHandler = () => {
    setNavOpen((prev) => !prev);
  };
  const currentUser = getCurrentUser();
  const requestLogout = async () => {
    var res = await authServices.requestDatalogout();
    window.location.reload();
  };
  return (
    <Box borderBottom={[null, null, "1px solid #D3D2D3"]} py="24px">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        borderRadius="8px"
        className="container"
        as="nav"
      >
        <Link to="/">
          <Image src={connectLogo} alt="Connect logo"></Image>
        </Link>
        <Flex
          display={["none", null, "flex"]}
          gap="24px"
          sx={{
            ".link": {
              fontSize: "16px",
              fontWeight: "500",
              color: "#0A1429",
            },
          }}
        >
          <Link to="/about" className="link">
            About Us
          </Link>
          <HashLink to="#plans" className="link">
            Pricing
          </HashLink>
        </Flex>
        <Flex gap="16px" display={["none", null, "flex"]}>
          {/* {!currentUser && (
            <>
              <a
                href={
                  process.env.REACT_APP_BASE_URL +
                  "/login?redirect=" +
                  encodeURIComponent(window.location.origin)
                }
                style={{
                  color: "#93D50A",
                  padding: "14px 30px",
                  border: "1px solid #93D50A",
                  borderRadius: "8px",
                }}
              >
                Login
              </a>
              <HashLink
                to="#plans"
                style={{
                  color: "#fff",
                  backgroundColor: "#93D50A",
                  borderRadius: "8px",
                  padding: "16px 32px",
                }}
              >
                Join Us
              </HashLink>
            </>
          )} */}
          {!currentUser && (
            <Flex alignItems="center" gap="18px">
              <Text fontSize={["16px"]} color="#6C727F">
                Already have an account?
              </Text>
              <Button
                as="a"
                href={
                  process.env.REACT_APP_BASE_URL +
                  "/login?redirect=" +
                  encodeURIComponent(window.location.origin)
                }
                bgColor="white.100"
                color="#344054"
                border="1px solid #D0D5DD"
              >
                Sign In
              </Button>
            </Flex>
          )}
          {currentUser && (
            <>
              <a
                href={process.env.REACT_APP_BASE_URL + "/account/clubconnect"}
                style={{
                  color: "#fff",
                  backgroundColor: "#93D50A",
                  borderRadius: "8px",
                  padding: "16px 32px",
                }}
              >
                My Account
              </a>
              <Button
                onClick={requestLogout}
                _hover={{
                  background: "none",
                }}
                sx={{
                  color: "#fd8205",
                  padding: "14px 30px",
                  border: "1px solid #fd8205",
                  borderRadius: "8px",
                  background: "none",
                  height: "auto",
                }}
              >
                Log out
              </Button>
            </>
          )}
        </Flex>
        <Box
          onClick={toggleNavHandler}
          display={["block", null, "none"]}
          sx={{
            ".icon": {
              fontSize: "45px",
            },
          }}
        >
          {!navOpen && <IoIosMenu className="icon" />}
          {navOpen && <IoIosClose className="icon" />}
        </Box>
      </Flex>
      <MobileNav
        navOpen={navOpen}
        setNavOpen={setNavOpen}
        currentUser={currentUser}
        requestLogout={requestLogout}
      />
    </Box>
  );
};

export default NavBar;

const MobileNav = (props) => {
  const { navOpen, currentUser, requestLogout, setNavOpen } = props;
  return (
    <Flex
      onClick={() => setNavOpen(false)}
      display={["flex", null, "none"]}
      position="absolute"
      left="20px"
      w="90vw"
      h="100vh"
      bgColor="#eee"
      direction="column"
      gap="24px"
      alignItems="center"
      justifyContent="center"
      zIndex="10"
      transform={navOpen ? "translateX(0)" : "translateX(120%)"}
      transition="all 1s"
    >
      <Link
        to="/about"
        className="link"
        style={{ color: "#0A1429", fontWeight: "500" }}
      >
        About Us
      </Link>
      <HashLink
        to="#plans"
        className="link"
        style={{ color: "#0A1429", fontWeight: "500" }}
      >
        Pricing
      </HashLink>
      {/* <Link
        to="/"
        style={{
          color: "#93D50A",
          padding: "14px 30px",
          border: "1px solid #93D50A",
          borderRadius: "8px",
        }}
      >
        Login
      </Link>
      <Link
        to="/#plans"
        style={{
          color: "#fff",
          backgroundColor: "#93D50A",
          borderRadius: "8px",
          padding: "16px 32px",
        }}
      >
        Join Us
      </Link> */}
      {!currentUser && (
        <>
          <a
            href={
              process.env.REACT_APP_BASE_URL +
              "/login?redirect=" +
              encodeURIComponent(window.location.origin)
            }
            style={{
              color: "#93D50A",
              padding: "14px 30px",
              border: "1px solid #93D50A",
              borderRadius: "8px",
            }}
          >
            Sign In
          </a>
        </>
      )}
      {currentUser && (
        <>
          <a
            href={process.env.REACT_APP_BASE_URL + "/account/clubconnect"}
            style={{
              color: "#fff",
              backgroundColor: "#93D50A",
              borderRadius: "8px",
              padding: "16px 32px",
            }}
          >
            My Account
          </a>
          <Button
            onClick={requestLogout}
            _hover={{
              background: "none",
            }}
            sx={{
              color: "#fd8205",
              padding: "14px 30px",
              border: "1px solid #fd8205",
              borderRadius: "8px",
              background: "none",
              height: "auto",
            }}
          >
            Log out
          </Button>
        </>
      )}
    </Flex>
  );
};
