import React, { lazy, Suspense, Fragment } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loader from "../views/Loader";

export default function RouteRegistrar({ history }) {
  return (
    <BrowserRouter navigator={history} location={history.location}>
      {RouteRenderer([
        // Register your routes here
        {
          path: "/",
          exact: true,
          component: import("../views/Home"),
          layout: import("../layouts/Main"),
        },
        {
          path: "/about",
          exact: true,
          component: import("../views/AboutUs"),
          layout: import("../layouts/Main"),
        },
        {
          path: "/google/redirect",
          exact: true,
          component: import("../views/Gglogin_page"),
          layout: import("../layouts/Main"),
        },
        // {
        //     path: '/subscribe/:plan_id',
        //     exact: true,
        //     component: import('../views/Subscribe'),
        //     layout: import('../layouts/Main'),
        // },
        {
          path: "/subscribe/:plan_id",
          exact: true,
          component: import("../views/Checkout"),
          layout: import("../layouts/Main"),
        },

        {
          path: "/coversationAPI/:plan_id",
          exact: true,
          component: import("../views/conversationApi"),
          layout: import("../layouts/Main"),
        },
        {
          path: "/google/redirect",
          exact: true,
          component: import("../views/GoogleCode"),
          layout: import("../layouts/Main"),
        },
        {
          path: "/PayStackInterface/:plan_id/:email/:phone/:name/:price",
          exact: true,
          component: import("../views/PayStackInterface"),
          layout: import("../layouts/Main"),
        },
        {
          path: "/success/:plan_id",
          exact: true,
          component: import("../views/Success"),
          layout: import("../layouts/Main"),
        },
      ])}
    </BrowserRouter>
  );
}

function RouteRenderer(routes = []) {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = lazy(() => route.layout) || Fragment;
          const Component = lazy(() => route.component);
          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              element={
                <Guard>
                  <Layout>
                    <Component />
                  </Layout>
                </Guard>
              }
            />
          );
        })}
      </Routes>
    </Suspense>
  );
}
