import { Box, Grid, Image, Text, Button, Flex } from "@chakra-ui/react";
import {
  groupwork,
  personOne,
  personTwo,
  personThree,
  heroImage,
} from "../assets";
import { HashLink } from "react-router-hash-link";

const HeroSection = () => {
  const PEOPLE = [personOne, personTwo, personThree];
  return (
    <Grid
      templateColumns={["1fr", null, "1fr 1fr"]}
      as="section"
      mt={["30px", null, "64px"]}
      className="container"
      rowGap="20px"
    >
      <Box>
        <Text
          as="h2"
          color="#86D845"
          fontSize={["38px", null, "64px"]}
          fontWeight="500"
        >
          Accelerate
        </Text>
        <Text
          as="h2"
          color="black.500"
          fontSize={["38px", null, "64px"]}
          fontWeight="500"
          mb={["10px", null, "24px"]}
          w="75%"
        >
          Your Business Success
        </Text>
        <Text
          as="p"
          color="black.500"
          fontWeight="500"
          fontSize={["14px", null, "24px"]}
          mb={["16px", null, "32px"]}
        >
          Made for Businesses
        </Text>
        <HashLink to="/#plans">
          <Button
            _hover={{
              opacity: "0.8",
            }}
            color="white.100"
            bgColor="#35BB25"
            borderRadius="8px"
            // p={["8px 19px", null, "16px 32px"]}
            py={["8px", null, "16px"]}
            w={["50%", null, "40%"]}
            mb={["16px", null, "32px"]}
          >
            Join the Club
          </Button>
        </HashLink>
        {/* <Flex alignItems="flex-start" gap="5px">
          <Flex>
            {PEOPLE.map((person, index) => (
              <Image
                src={person}
                key={index}
                ml={index === 0 ? "0px" : "-6px"}
              />
            ))}
          </Flex>
          <Box
            height="8px"
            width="8px"
            bgColor="#00B080"
            borderRadius="50%"
          ></Box>
          <Text as="p">5m+ world active users</Text>
        </Flex> */}
      </Box>
      <Box>
        <Image
          src={heroImage}
          alt="Hero Image"
          w="100%"
          h="100%"
          objectFit="cover"
        />
      </Box>
    </Grid>
  );
};

export default HeroSection;
