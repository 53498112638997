import { SHA256 } from 'crypto-js'

// Convert an html list to a string array
export function htmlListToArray(htmlList) {
  const document = new DOMParser().parseFromString(htmlList, "text/html");
  const listItems = document.querySelectorAll("li");
  const listArray = Array.from(listItems).map((item) => item.textContent || "");
  return listArray.filter((item) => item !== "");
}

// Check if a string is html
export function isHtml(str) {
    const doc = new DOMParser().parseFromString(str, "text/html");
    return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
}

// Convert days to months
export function daysToMonths(days) {
    if (days < 30) {
        return days + " days";
    }
    const months = Math.floor(days / 30);
    return `${months} months`;
}

// Commafies a number
export function commafy(num, removeDecimal = false) {
    if (removeDecimal) {
        num = Math.floor(num);
    }
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// Decode base64
export const base64Decode = (data) => {
    data = atob(data);
    return JSON.parse(data);
};

// Get cookie
export const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
};

// Request for current user
export const getCurrentUser = () => {
    var auth_hash = getCookie("auth:hash");
    if (!auth_hash) {
        return null;
    }
    return base64Decode(auth_hash);
};


// Set cookie
/**
 *
 * @param {string} name - The name of the cookie
 * @param {string} value - The value of the cookie
 * @param {int} days - The number of days until the cookie expires
 */

export const setCookie = (name, value, days) => {
    var expires = '';
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie =
      name +
      '=' +
      (value || '') +
      expires +
      '; path=/; domain=.' +
      process.env.REACT_APP_DOMAIN_NAME;
};

// Encode to base64
export const base64Encode = data => {
    data = JSON.stringify(data);
    return btoa(data);
};



export const deleteCookie = name => {
    setCookie(name, '', -1);
    return;
};


export const handleHash = (data) => {
    const hashed = SHA256(data).toString();
    return hashed;
};