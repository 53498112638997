import { Box, Grid, Text, Flex, Image } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { WA, connectLogo, connectNigeria } from "../assets";
import { FOOTER_LINKS, SOCIALS } from "../utils/data";
import { Button } from "bootstrap";
const Footer = () => {
  return (
    <Box
      as="footer"
      pt={["32px", null, "64px"]}
      pb={["20px", null, "40px"]}
      borderTop="1px solid #EBEBEB"
    >
      <Grid
        templateColumns={["1fr", null, "1fr 1fr"]}
        mb={["20px", null, "40px"]}
        className="container"
        rowGap="32px"
      >
        <a href="https://connectnigeria.com/">
          <Image src={connectNigeria} alt="Connect Nigeria logo" />
        </a>

        <Grid templateColumns={["1fr 1fr", null, "1fr 1fr 1fr"]} rowGap="24px">
          {FOOTER_LINKS.map((data, index) => (
            <FooterLinks title={data.title} links={data.links} key={index} />
          ))}
        </Grid>
        {/* <Flex
          direction="column"
          gap={["8px", null, "16px"]}
          alignItems="flex-start"
          w="80%"
          justifySelf={["start", null, "end"]}
        >
          <Text as="p" color="grey.800" fontSize={["14px", null, "16px"]}>
            Club Connect helps me grow my business. I have been connected to the
            right network and at the same time I have been rewarded with
            training and mentoring. I highly recommend this to all.
          </Text>
          <Flex gap="16px">
            {SOCIALS.map((social, index) => (
              <a href={social.link} target="_blank">
                <Image src={social.logo} key={index} />
              </a>
            ))}
          </Flex>
        </Flex> */}
      </Grid>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        direction={["column-reverse", null, "row"]}
        className="container"
        borderTop="1px solid #EBEBEB"
        pt={["12px", null, "24px"]}
        gap="16px"
      >
        <Text color="balck.500">
          ©2023ConnectNigeria.com — All Rights Reserved
        </Text>
        <Flex gap="24px">
          {SOCIALS.map((social, i) => (
            <a href={social.link} target="_blank" key={i}>
              <Image src={social.logo} />
            </a>
          ))}
        </Flex>
      </Flex>
      <Flex
        mt="10px"
        className="container"
        justifyContent={["center", null, "start"]}
        as="a"
        href="https://wa.me/+2348098005000"
      >
        <Image src={WA} alt="Whatsapp Logo" width="24px" height="24px" />
        <Text>+2348098005000</Text>
      </Flex>
      {/* <Text
        bgColor="green.100"
        py={["14px", null, "18px"]}
        fontSize="16px"
        textAlign="center"
        textTransform="uppercase"
        fontWeight="bold"
      >
        Developed by{" "}
        <Link
          to="https://www.linkedin.com/in/ridwan-olaniran/"
          style={{ color: "#f00" }}
        >
          Olaniran Ridwan
        </Link>
      </Text> */}
    </Box>
  );
};

export default Footer;

const FooterLinks = (props) => {
  const { title, links: LINKS } = props;
  return (
    <Box>
      <Text mb={["4px", null, "8px"]} color="#6C727F" textTransform="uppercase">
        {title}
      </Text>
      <Flex
        direction="column"
        gap={["4px", null, "8px"]}
        sx={{
          a: {
            color: "#0A1429",
            fontSize: ["14px", null, "16px"],
          },
        }}
      >
        {LINKS.map((link, index) => {
          return link.to ? (
            <Link to={link.to} key={index}>
              {link.text}
            </Link>
          ) : (
            <a href={link.url} target="_blank" key={index}>
              {link.text}
            </a>
          );
        })}
      </Flex>
    </Box>
  );
};
