import {
  funding,
  advertising,
  consulting,
  partnerships,
  presentation,
  promise,
  twitter,
  facebook,
  instagram,
  ridwan,
  advertisingImg,
  fundingImg,
  consultingImg,
  partnershipImg,
  Seminar,
  IG,
  TW,
  FB,
  LK,
} from "../assets";
export const BANNERS = [
  {
    title: "Advertising",
    text: "Increase your business awareness. Ensure your content reaches the greatest number of targeted people possible. Get more conversions and engagements with our discounted advertising plans readily available to you.",
    bgColor: "#E5E3FF",
    icon: advertisingImg,
  },
  {
    title: "Funding Opportunity",
    text: "Every business needs a support system to thrive. Funding support even makes it much easier. With our club partners and merchants, you have ease of access to all the funding information you need for your business.",
    bgColor: "#DBFFDB",
    icon: fundingImg,
  },
  {
    title: "Discounted Consulting Service",
    text: "Tell us how easy doing business would be if you had the right information at the right time at your disposal. Better yet, the right consult. We are your plug for getting access to premium consultation without hassle.",
    bgColor: "#DCFFFF",
    icon: consultingImg,
  },
  {
    title: "Business Partnerships",
    text: `Increase your business opportunities through strategic collaborations. Although forming business alliances and strategic partnerships takes time and effort to foster. Join our ecosystem of partners to tap into each others' value and deliver solutions to your company and customers.`,
    bgColor: "#FFF1F1",
    icon: partnershipImg,
  },
  {
    title: "Power Training and Seminars",
    text: "Become a real guru in your industry with the right and relevant education from the Club activities. Our trainings are purposed to help you master the skills essential for commercial growth.",
    bgColor: "#E8FFFF",
    icon: Seminar,
  },
];
// title, price, duration, bgColor, features: FEATURES
export const PACKAGES = [
  {
    title: "Basic Access",
    price: "#15,000",
    duration: "03 Months",
    bgColor: "#FD8204",
    features: [
      "Access to database of CC members for 3 months",
      "Discount on services and products from members",
      "Attend one quarterly Business mixer event for FREE",
      "30% off advertising services on Connect Nigeria",
      "Free featured listing for 1 month",
      "One free featured article for the brand",
      "30% discount to attend the mixer for invitees",
      "One social media feature on the brand quarterly",
    ],
  },
  {
    title: "Yanga Access",
    price: "#30,000",
    duration: "06 Months",
    bgColor: "#93D50A",
    features: [
      "Access to database of CC members for 12 months",
      "Discount on services and products from members",
      "Attend 4 Business Mixer events for FREE",
      "One free dedicated newsletter blast",
      "30% off advertising services on Connect Nigeria",
      "Free featured listing for 3 months",
      "One free featured article for the brand",
      "One social media feature on the brand quarterly",
      "30% discount to attend the mixer for other invitees",
      "Meet the boss session for CEO",
    ],
  },
  {
    title: "Corporate Access",
    price: "#60,000",
    duration: "12 Months",
    bgColor: "#0A12D5",
    features: [
      "Attend the quarterly Business mixer events with up to two invitees for free",
      "30% discount to attend the mixer for your other invitees",
      "Get discounts from other members for the validity of your package",
      "Access to database of CC members for package duration",
      "Multiple user access for CEO and 2 staff",
      "Free mentoring/ Learning session Yearly",
      "30% off advertising services on CN",
      "Free featured listing for 3 months",
      "One free featured article for the brand",
      "One free Dedicated newsletter blast for the brand Quarterly",
      "One social media feature on the brand quarterly",
      "30% discount to attend the mixer for other invitees",
      "Free AD days on CN Platform",
      "Meet the boss session for CEO",
    ],
  },
];

export const TESTIMONIALS = [
  {
    name: "Folake Adebayo",
    username: "@folake",
    text: `Joining Club Connect has been the best decision for my business. The community's support and valuable resources have propelled my growth beyond expectations. I'm truly grateful for the connections and insights shared here.`,
    avatar: promise,
    social: twitter,
  },
  {
    name: "Chukwudi Nwosu",
    username: "@chukwudi",
    text: `Club Connect fosters a collaborative environment that has significantly impacted my business's success. The knowledge exchange and networking opportunities have opened new doors for partnerships and expansion. I can't imagine my entrepreneurial journey without this community`,
    avatar: promise,
    social: facebook,
  },
  {
    name: "Michael Eze",
    username: "@michael",
    text: `Club Connect is more than just a network; it's a support system that has helped me navigate the complexities of entrepreneurship. The camaraderie and encouragement from like-minded individuals have been instrumental in my professional and personal growth. I couldn't ask for a better community to be a part of.`,
    avatar: promise,
    social: instagram,
  },
  {
    name: "Ayodele Adeyemi",
    username: "@ayodele",
    text: `Being a part of Club Connect has been a game-changer for me. The collective wisdom and experiences shared by fellow entrepreneurs have provided me with actionable strategies to overcome challenges and drive my business forward. I'm proud to be a member of this thriving community.`,
    avatar: promise,
    social: facebook,
  },
  {
    name: "Thelma Okonkwo",
    username: "@thelkma",
    text: `Club Connect has been the catalyst for my business's exponential growth. The collaborative atmosphere and shared expertise have enabled me to implement innovative strategies and stay ahead in a competitive market. I highly recommend Club Connect to all aspiring and established entrepreneurs`,
    avatar: promise,
    social: instagram,
  },
  {
    name: "Ayodele Ayobami",
    username: "@ayodele",
    text: `Club Connect has not only expanded my business network but has also provided me with a platform to give back and contribute to the success of other entrepreneurs. The spirit of collaboration and mutual empowerment within this community is unparalleled. I'm grateful for the endless support and inspiration I receive here`,
    avatar: promise,
    social: twitter,
  },
];

export const FAQS = [
  {
    question: "What is ClubConnect",
    answer:
      "If you are looking for the right community to grow your business with ease, that is what Club Connect helps you to achieve. Our framework delivers to you strategic connections and tools that will move your business from where you are to where you want to be.",
  },
  {
    question: "Is it a free or paid Community?",
    answer:
      "Joining the community is not free. We have however created affordable plans which you can choose from to be a part of the community.",
  },
  {
    question: "Can i get a discount when i join the club?",
    answer:
      "There are no discounts on pricing plans but when you join Club Connect, you will have access to discounted training sessions, advertising and consulting services from our Club merchants.",
  },
  {
    question: "How do i join Clubconnect?",
    answer: "It’s as easy as 1-2-3. Just click club.connectnigeria.com.",
  },
  {
    question: "Can I offer my services to the ClubCoonect Family?",
    answer:
      "Of course. We love ClubConnect members who also offer services to the community. Once you join Club Connect, you have the opportunity to offer your service to all our members. We’ll love to see you get value for value.",
  },
  {
    question: "What kind of Business can join ClubConnect?",
    answer:
      "ClubConnect is built for businesses. As long as you are operating a legal and registered business in Nigeria, we definitely want to have you join us. If you have not listed your business on our platform, do so HERE for FREE.",
  },
];

export const FOOTER_LINKS = [
  {
    title: "NAVIGATION",
    link: [
      "Food & Drinks",
      "Events",
      "Business",
      "Services",
      "Marketplace",
      "Jobs",
      "Real Estate",
    ],
    links: [
      {
        text: "Events",
        url: "https://events.connectnigeria.com",
      },
      {
        text: "Business",
        url: "https://business.connectnigeria.com",
      },
      {
        text: "Services",
        to: "/",
      },
      {
        text: "Marketplace",
        to: "/",
      },
      {
        text: "Jobs",
        url: "https://jobs.connectnigeria.com",
      },
      {
        text: "Real Estate",
        url: "https://realestate.connectnigeria.com",
      },
    ],
  },
  {
    title: "ABOUT",
    link: [
      "Who We Are",
      "Contact Us",
      "FAQ's",
      "Terms Of Use",
      "Privacy Policy",
    ],
    links: [
      {
        text: "Who We Are",
        url: "https://connectnigeria.com/about-us",
      },
      {
        text: "Contact Us",
        url: "https://connectnigeria.com/contact-us",
      },
      {
        text: "FAQ's",
        url: "https://connectnigeria.com/frequently-asked-questions",
      },
      {
        text: "Terms Of Use",
        url: "https://connectnigeria.com/terms-and-conditions",
      },
      {
        text: "Privacy Policy",
        url: "https://connectnigeria.com/privacy-policy",
      },
    ],
  },
  {
    title: "STAY UPDATED",
    link: ["Advertise", "Articles", "CNTV", "Business Mixer", "Club Connect"],
    links: [
      {
        text: "Advertise",
        url: "https://connectnigeria.com/advertise",
      },
      {
        text: "Articles",
        url: "https://articles.connectnigeria.com/",
      },
      {
        text: "CNTV",
        url: "https://cntv.connectnigeria.com/",
      },
      {
        text: "Business Mixer",
        url: "https://www.connectnigeria.com/bizmix",
      },
      {
        text: "Club Connect",
        to: "/",
      },
    ],
  },
];

export const SOCIALS = [
  {
    link: "https://www.facebook.com/ConnectNigeria/?_rdc=1&_rdr",
    logo: FB,
  },
  {
    link: "https://www.facebook.com/ConnectNigeria/?_rdc=1&_rdr",
    logo: LK,
  },

  {
    link: "https://twitter.com/ConnectNigeria",
    logo: TW,
  },
  {
    link: "https://www.instagram.com/connectnigeria/",
    logo: IG,
  },
];
