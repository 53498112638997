import axios from "axios";
import { deleteCookie } from '../utils/utils';

export const authServices = {
    login: _login,
    register: _register,
    delete: _delete,
    logout: _logout,
    user: _user,
    requestDataLogin:requestDataLogin,
    requestDataRegister:requestDataRegister,
    requestDatalogout:requestDatalogout,
    requestGooglelogin:requestGooglelogin,
    verifyGoogleAccount:verifyGoogleAccount
};

async function _login(username, password) {
    return axios.post("/users/authenticate", { username, password })
        .then(handleResponse)
        .then(user => {
            localStorage.setItem("user", JSON.stringify(user));
            return user;
        });
};

async function requestDatalogout(){
    deleteCookie('auth:hash');
}


async function requestDataRegister(data){
    return axios.post(`${process.env.REACT_APP_API_URL}/user/auth/register`, data)
    .then(response => {
        return {
            success: true,
            data: response.data
        };
    })
    .catch(error => {
        return {
            success: false,
            error: error.response.data
        };
    });
}


async function requestDataLogin(data){
    return axios.post(`${process.env.REACT_APP_API_URL}/user/auth/login`, data)
    .then(response => {
    	return {
            success: true,
            data: response.data
        };
    })
    .catch(error => {
        return {
            success: false,
            error: error.response.data
        };
    });
}

async function _register(user) {
    return axios.post("/users/register", user).then(handleResponse);
}

async function _delete(id) {
    return axios.delete(`/users/${id}`).then(handleResponse);
}

function _logout() {
    localStorage.removeItem("user");
}

function _user() {
    return JSON.parse(localStorage.getItem("user"));
}

function handleResponse(response) {
    return response.data;
}

async function requestGooglelogin(){
    return axios.get(`${process.env.REACT_APP_API_URL}/user/auth/google/redirect-url`)
    .then(response => {
    	return {
            success: true,
            data: response.data
        };
    })
    .catch(error => {
        return {
            success: false,
            error: error.response.data
        };
    });
}


async function verifyGoogleAccount(data){
    return axios.post(`${process.env.REACT_APP_API_URL}/user/auth/social/google`, data)
    .then(response => {
    	return {
            success: true,
            data: response.data
        };
    })
    .catch(error => {
        return {
            success: false,
            error: error.response.data
        };
    });
}