import { Box, Flex, Text, Switch, Image, Button, Grid } from "@chakra-ui/react";
import { checkIcon } from "../assets";
import { PACKAGES } from "../utils/data";
import { useEffect, useState } from "react";
import { commafy, daysToMonths, htmlListToArray } from "../utils/utils";
const Packages = () => {
  const [plans, setPlans] = useState([]);
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/club-connect/generic/subscription-plans`
        );
        const data = await res.json();
        const resPlans = data.data.subscription_plans;
        resPlans.sort(
          (a, b) => parseInt(a.price_in_ngn) - parseInt(b.price_in_ngn)
        );
        setPlans(resPlans);
      } catch (err) {
        console.log(err);
      }
    };
    fetchPlans();
  }, []);
  return (
    <Box
      id="plans"
      as="section"
      className="container"
      mt={["32px", null, "64px"]}
      mb={["50px", null, "100px"]}
    >
      <Flex direction="column" alignItems="center">
        <Text
          as="h2"
          color="black.100"
          fontSize={["24px", null, "48px"]}
          fontWeight="medium"
          mb={["8px", null, "16px"]}
          w={["100%", null, "30%"]}
          textAlign="center"
        >
          Ready to Join our Community?
        </Text>
        <Text
          as="p"
          color="grey.500"
          fontSize={["12px", null, "20px"]}
          mb={["12px", null, "24px"]}
        >
          Choose the package that suit you
        </Text>
        <Flex
          gap="8px"
          alignItems="center"
          sx={{
            p: {
              color: "green.100",
              fontWeight: "500",
              fontSize: ["12px", null, "16px"],
            },
          }}
        >
          <Text as="p">Bill Monthly</Text>
          <Switch colorScheme="teal" size={["md", null, "lg"]} />
          <Text as="p">Annual Anniversary pricing (save 50%)</Text>
        </Flex>
      </Flex>
      <Grid
        templateColumns={["1fr", null, "1fr 1fr 1fr"]}
        columnGap="32px"
        rowGap="16px"
        mt={["24px", null, "48px"]}
      >
        {plans?.map((plan, index) => {
          const bgColors = ["#EF8C37", "#35BB25", "#1A5D12"];
          return (
            <PackageBox
              data={plan}
              key={index}
              index={index}
              bgColor={bgColors[index]}
            />
          );
        })}
      </Grid>
    </Box>
  );
};

export default Packages;

const PackageBox = (props) => {
  const { data, index, bgColor } = props;
  const {
    name,
    duration_in_days: duration,
    price_in_ngn: price,
    description,
    id,
  } = data;
  return (
    <Box
      as="article"
      p={["10px", null, "20px"]}
      border="1px solid #DFE5E6"
      borderRadius="8px"
      display="flex"
      flexDirection="column"
    >
      <Text
        as="h2"
        fontWeight="semibold"
        fontSize={["16px", null, "24px"]}
        color="black.200"
        mb={["8px", null, "12px"]}
      >
        {name}
      </Text>
      <Text
        as="p"
        fontWeight="bold"
        fontSize={["20px", null, "40px"]}
        color="black.200"
      >
        {`₦ ${commafy(price)}`}
      </Text>
      <Text
        as="p"
        fontSize={["12px", null, "14px"]}
        color="black.200"
        my={["8px", null, "12px"]}
      >
        {`${daysToMonths(duration)}`}
      </Text>
      <Flex
        direction="column"
        gap={["8px", null, "16px"]}
        mb={["12px", null, "24px"]}
      >
        {htmlListToArray(description).map((feature, index) => (
          <Flex key={index} gap={["6px", null, "12px"]} alignItems="center">
            <Image src={checkIcon} alt="check icon" />
            <Text color="grey.600" fontSize={["12px", null, "14px"]}>
              {feature}
            </Text>
          </Flex>
        ))}
      </Flex>
      <Button
        onClick={() => {
          window.location.href = `/subscribe/${id}`;
        }}
        w="100%"
        borderRadius="12px"
        bgColor={bgColor}
        color="white.100"
        py="12px"
        fontSize={["12px", null, "16px"]}
        mt="auto"
        _hover={{
          opacity: 0.7,
        }}
      >
        Get Started
      </Button>
    </Box>
  );
};
