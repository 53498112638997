import React, { useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import { getCurrentUser } from "../utils/utils";
import cloudConnectLogo from "../assets/img/cloud-connect-logo.png";
import useOutsideClick from "../hooks/useOutsideClick";
import Footer from "../views/Footer";
import { useState, useRef } from "react";
import { List as Hamburger, XLg as Close } from "react-bootstrap-icons";
import ReactGa from "react-ga";
import { authServices } from "../services/auth";

import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { NavBar } from "../views";
const MainLayout = ({ children }) => {
  const tawkMessengerRef = useRef();
  const currentUser = getCurrentUser();

  const [isActive, setIsActive] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => setIsActive(false));
  async function requestLogout() {
    var res = await authServices.requestDatalogout();
    window.location.reload();
  }
  return (
    <>
      <TawkMessengerReact
        propertyId="5f43a3c01e7ade5df4436619"
        widgetId="default"
        ref={tawkMessengerRef}
      />
      <NavBar />
      {children}
      <Footer />
    </>
  );
};
export default MainLayout;
